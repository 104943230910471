import styled from '@emotion/styled';
import { mq_min } from '@src/styles/breakpoints';
import { color_white, color_black, color_miami } from '@src/styles/colors';
import { getStyleSizes } from '@src/styles/styles.utils';
import { zIndices } from '@styles/z-indices';
import {
  LCMenuNavProps,
  LogoTextProps,
  MenuItemProps,
  MenuProps,
} from './lc-menu.interface';
import { causten_font } from '@styles/typography';

export const LCMenuNavContainer = styled('div')`
  min-height: ${getStyleSizes(72)};
`;

export const LCMenuNav = styled('nav')<LCMenuNavProps>`
  background: ${({ backgroundColor }) =>
    backgroundColor === 'light' ? color_white : color_black};
  position: fixed;
  width: 100%;
  z-index: ${zIndices.learninCenterNav};
  top: ${getStyleSizes(70)};
  left: 0;
  height: ${getStyleSizes(72)};
  display: flex;
  align-items: center;

  ${mq_min('lg')} {
    top: ${getStyleSizes(50)};
  }
`;

export const LCMenuNavStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const LogoText = styled.div<LogoTextProps>`
  color: ${color_miami.base};
  font-size: ${getStyleSizes(18)};
  font-family: ${causten_font};
  font-weight: 600;
  display: flex;
  align-items: center;
`;

export const Menu = styled.ul<MenuProps>`
  font-weight: 400;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-direction: column;
  position: absolute;
  ${mq_min('lg')} {
    display: flex;
    flex-direction: row;
    position: relative;
  }
`;

export const MenuItem = styled.li<MenuItemProps>`
  color: ${({ backgroundColor }) =>
    backgroundColor === 'light' ? color_black : color_white};
  font-weight: ${({ active }) => (active ? 600 : 400)};
  padding: ${getStyleSizes([0, 16])};
  cursor: pointer;
`;

export const MenuMobile = styled.select`
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 0;
  top: 0;
  height: 100%;
`;

export const LeftMenuStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const LogoTextStyled = styled.div`
  display: flex;
  color: ${color_miami.base};
`;
