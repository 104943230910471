import React from 'react';
import { useTopicsFilter } from '../../context/topics-filter.context';
import { LCTopicsProps } from '../lc-menu.interface';
import { DropdownNativeStyled } from './dropdown.styles';

export const DropdownFiltersNative = () => {
  const { activeItems, toggleHandler } = useTopicsFilter();
  const data = activeItems?.filter(
    (item: LCTopicsProps) => item.active === false
  );

  return (
    <DropdownNativeStyled
      value={activeItems?.find((item) => item.active)?.id}
      onChange={(e: any) => {
        const id = e.currentTarget.value;
        const item = data?.find((item) => item.id === id);
        item && toggleHandler(item);
      }}
    >
      <option value={''}>Select...</option>
      {data?.map((item: LCTopicsProps, key: number) => {
        return (
          <option value={item.id} key={key}>
            {item?.display_name}
          </option>
        );
      })}
    </DropdownNativeStyled>
  );
};
