import { LCCategorySectionMin } from '@src/cms-components/learning-center/lc-category/lc-category.interface';
import {
  LCMenuData,
  LCTopicsProps,
} from '../components/learning-center/lc-menu/lc-menu.interface';
import { encodeURItopic, getTopicsFromURL } from '../utilities/filters.utils';

export const menuNavMapper = (
  LCTopics: LCTopicsProps[],
  LCSections: LCCategorySectionMin[],
  rootPage: string,
  navigation_name: string = ''
) => {
  const topicsURL = getTopicsFromURL();

  const topics = LCTopics?.map((topic) => ({
    ...topic,
    url: encodeURItopic(topic?.display_name.toLowerCase()),
    active: topicsURL?.some(
      (f: string) => f === topic?.display_name.toLowerCase()
    ),
  }));

  const menu = LCSections.filter((section) => section?.resourcesLength).map(
    (section: LCCategorySectionMin) => ({
      url: `${rootPage}/${section?.url_segment}`,
      label: section?.navigation_name,
      active: section?.navigation_name === navigation_name,
    })
  ) as LCMenuData[];

  return {
    topics,
    menu,
  };
};
