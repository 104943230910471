import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import { LCTopicsProps } from '@shared/components/learning-center/lc-menu/lc-menu.interface';
import { updateTopicsURL } from '@src/shared/utilities/filters.utils';

export type TopicFilterDefaultValue = {
  activeItems: LCTopicsProps[];
  toggleTargetTopic: (value: LCTopicsProps) => void;
  deactivateAll: () => void;
};

const topicFilterDefaultValue: TopicFilterDefaultValue = {
  activeItems: [],
  toggleTargetTopic: (value: LCTopicsProps) => value,
  deactivateAll: () => {},
};

const TopicsFilterContext = createContext(topicFilterDefaultValue);

const TopicsFilterProvider = ({
  children,
  topics,
}: PropsWithChildren<{ topics: LCTopicsProps[] }>) => {
  const [activeItems, setActiveItems] = useState<LCTopicsProps[]>(topics || []);

  const deactivateAll = useCallback(() => {
    const updatedTopics: LCTopicsProps[] = activeItems?.map((currentTopic) => {
      return {
        ...currentTopic,
        active: false,
      };
    });
    updateTopicsURL();
    return setActiveItems(updatedTopics);
  }, [activeItems]);

  const toggleTargetTopic = useCallback(
    (topic: LCTopicsProps) => {
      const updatedTopics: LCTopicsProps[] = activeItems?.map((currentTopic) => {
        if (currentTopic.id === topic.id) {
          return {
            ...currentTopic,
            active: !topic.active,
          };
        }
        return currentTopic;
      });

      updateTopicsURL(updatedTopics?.filter((topic) => topic.active));
      return setActiveItems(updatedTopics);
    },
    [activeItems]
  );

  return (
    <TopicsFilterContext.Provider
      value={{ activeItems, toggleTargetTopic, deactivateAll }}
    >
      {children}
    </TopicsFilterContext.Provider>
  );
};

const useTopicsFilter = () => {
  const { activeItems, toggleTargetTopic, deactivateAll } =
    useContext(TopicsFilterContext);

  if (!activeItems) {
    throw new Error('You need the MainNavProvider');
  }

  const toggleHandler = useCallback(
    (item: LCTopicsProps) => {
      toggleTargetTopic(item);
    },
    [toggleTargetTopic, activeItems]
  );

  const clearAll = useCallback(() => {
    return deactivateAll();
  }, [toggleTargetTopic, activeItems]);

  return {
    activeItems,
    toggleHandler,
    clearAll,
  };
};

export { TopicsFilterProvider, useTopicsFilter };
