import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { mq_min } from '@src/styles/breakpoints';
import {
  color_darkest_grey,
  color_light_grey,
  color_white,
} from '@src/styles/colors';
import { getStyleSizes } from '@src/styles/styles.utils';
import { global_typography } from '@src/styles/typography';
import {
  TagItemWrapperStyledProps,
  TextStyledProps,
  WrapperStyledProps,
} from './Tags.interface';

export const TagsStyled = styled('nav')`
  display: flex;
  padding: ${getStyleSizes([75, 0, 20, 0])};
  flex-direction: column;
  ${mq_min('lg')} {
    flex-direction: row;
  }
`;

export const TagsWrapperStyled = styled('div')`
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  ${mq_min('lg')} {
    width: calc(100% - ${getStyleSizes(67)});
    justify-content: flex-end;
    margin-right: ${getStyleSizes(15)};
  }
`;

export const TagItemStyled = styled('span')`
  margin-right: ${getStyleSizes(10)};
`;

export const TagItemWrapperStyled = styled('div')<TagItemWrapperStyledProps>`
  display: flex;
  align-items: center;
  border: 1px solid ${color_light_grey};
  border-radius: 20px;
  font-family: Causten;
  font-weight: 500;
  font-size: ${getStyleSizes(global_typography.copy.base)};
  color: ${({ backgroundColor }) =>
    backgroundColor === 'dark' ? color_white : color_darkest_grey};
  padding: ${getStyleSizes([0, 20])};
  height: ${getStyleSizes(40)};
  margin: ${getStyleSizes(7)};

  ${mq_min('lg')} {
    margin: ${getStyleSizes([15, 15, 0, 0])};
  }
`;

export const CleanerStyled = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const TagItemIconStyled = styled(CloseIcon)`
  width: ${getStyleSizes(17)};
  cursor: pointer;
`;

export const WrapperStyled = styled('div')<WrapperStyledProps>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor === 'dark' ? color_darkest_grey : color_white};
`;

export const TextStyled = styled.div<TextStyledProps>`
  width: ${getStyleSizes(67)};
  height: ${getStyleSizes(50)};
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: Causten;
  font-weight: 600;
  font-size: ${getStyleSizes(13)};
  position: relative;
  padding-top: ${getStyleSizes(15)};
  color: ${({ backgroundColor }) =>
    backgroundColor === 'dark' ? color_white : color_darkest_grey};
  &:before {
    content: '';
    position: absolute;
    width: ${getStyleSizes(67)};
    border-top: 1px solid
      ${({ backgroundColor }) =>
        backgroundColor === 'dark' ? color_white : color_darkest_grey};
    top: ${getStyleSizes(42)};
    left: 0;
  }
`;
