import { useTopicsFilter } from '@shared/components/learning-center/context/topics-filter.context';
import CMSComponentWrapper from '@src/shared/components/component-wrapper';
import React from 'react';
import { LCTopicsProps } from '../lc-menu.interface';
import { TagsProps } from './Tags.interface';
import {
  CleanerStyled,
  TagItemIconStyled,
  TagItemStyled,
  TagItemWrapperStyled,
  TagsStyled,
  TagsWrapperStyled,
  TextStyled,
  WrapperStyled,
} from './Tags.styles';

export const Tags = ({ backgroundColor = 'light' }: TagsProps) => {
  const { activeItems, toggleHandler, clearAll } = useTopicsFilter();
  const show = activeItems.some((item) => item.active);
  return (
    <>
      {show && (
        <WrapperStyled backgroundColor={backgroundColor}>
          <CMSComponentWrapper maxWidth={1240}>
            <TagsStyled>
              <TagsWrapperStyled>
                {activeItems
                  ?.filter((item: LCTopicsProps) => item.active)
                  .map((item: LCTopicsProps, key: number) => (
                    <TagItemWrapperStyled
                      key={key}
                      backgroundColor={backgroundColor}
                    >
                      <TagItemStyled>{item.display_name}</TagItemStyled>
                      <TagItemIconStyled onClick={() => toggleHandler(item)} />
                    </TagItemWrapperStyled>
                  ))}
              </TagsWrapperStyled>
              {show && (
                <CleanerStyled onClick={() => clearAll()}>
                  <TextStyled backgroundColor={backgroundColor}>
                    Clear filters
                  </TextStyled>
                </CleanerStyled>
              )}
            </TagsStyled>
          </CMSComponentWrapper>
        </WrapperStyled>
      )}
    </>
  );
};
