import styled from '@emotion/styled';
import ExpandLessMore from '@mui/icons-material/ExpandMoreOutlined';
import { mq_min } from '@src/styles/breakpoints';
import { color_black, color_white } from '@src/styles/colors';
import { getStyleSizes } from '@src/styles/styles.utils';
import { zIndices } from '@src/styles/z-indices';
import {
  MenuItemLabelProps,
  NavItemIconStyledProps,
  NavItemLinkStyledProps,
  NavPanelProps,
} from './filters.interface';

export const NavItemLinkStyled = styled.button<NavItemLinkStyledProps>`
  border: 0;
  background: transparent;
  font-family: 'Causten', Arial, sans-serif;
  color: ${color_black};
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  width: 100%;
  height: auto;
  color: ${({ color }) => (color === 'dark' ? color_white : color_black)};
`;

export const NavItemIconStyled = styled(ExpandLessMore)<NavItemIconStyledProps>`
  width: ${getStyleSizes(28)};
  transition: transform 100ms linear;
  ${({ state }) =>
    `transform: ${state === 'open' ? 'rotateX(180deg);' : 'rotateX(0);'}`};
`;

export const MenuItemLabel = styled.div<MenuItemLabelProps>`
  font-size: ${getStyleSizes(16)};
  font-weight: 500;
  word-break: keep-all;
  cursor: pointer;

  ${mq_min('lg')} {
    font-weight: ${({ state }) => (state === 'open' ? 600 : 'normal')};
  }
`;

export const NavPanel = styled.div`
  padding: ${getStyleSizes([0, 0, 0, 0])};
  min-width: 150px;
  ${mq_min('lg')} {
    background: ${color_white};

    box-shadow: ${getStyleSizes([0, 4, 30])} rgba(199, 200, 205, 0.25);
    border-radius: ${getStyleSizes(8)};
  }
`;

export const NavPanelStyled = styled.div<NavPanelProps>`
  ${({ state }) => `display: ${state === 'open' ? 'block' : 'none'}`};
  position: absolute;
  padding: ${getStyleSizes([10, 0, 0, 0])};
  z-index: ${zIndices.header + 1};
  right: 0;
`;

export const NavFilter = styled.div`
  position: relative;
`;
export const NavSubItem = styled.div`
  padding: ${getStyleSizes([10, 15, 10, 15])};
  cursor: pointer;
`;
