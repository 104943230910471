import { useState, useCallback } from 'react';

export const useNav = () => {
  const [open, setOpen] = useState(false);
  const toggle = useCallback(() => setOpen((o) => !o), [setOpen]);

  return {
    open,
    toggle,
  };
};
