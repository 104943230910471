import React from 'react';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-emotion';
import { DropdownFiltersNative } from '../dropdown';
import { LCTopicsProps } from '../lc-menu.interface';
import { FiltersProps } from './filters.interface';
import {
  MenuItemLabel,
  NavFilter,
  NavItemIconStyled,
  NavItemLinkStyled,
  NavPanel,
  NavPanelStyled,
  NavSubItem,
} from './filters.styles';
import { useNav } from './hooks/useNav';
import { useTopicsFilter } from '@shared/components/learning-center/context/topics-filter.context';

const DEFAULT_FILTER = {
  title: 'Filter by Topic',
  display_name: 'Filter by Topic',
  active: true,
};

export const Filters: React.FC<FiltersProps> = ({ items, backgroundColor }) => {
  const { open, toggle } = useNav();
  const { activeItems, toggleHandler } = useTopicsFilter();

  const isDesktop = useBreakpoint(up('lg'));

  const data = activeItems?.filter(
    (item: LCTopicsProps) => item.active === false
  );

  return (
    <NavFilter>
      <NavItemLinkStyled onClick={() => toggle()} color={backgroundColor}>
        <MenuItemLabel state={open ? 'open' : 'closed'}>
          {DEFAULT_FILTER?.display_name}
        </MenuItemLabel>
        <NavItemIconStyled state={open ? 'open' : 'closed'} />
      </NavItemLinkStyled>
      {!!items.length && isDesktop ? (
        <NavPanelStyled state={open ? 'open' : 'closed'}>
          <NavPanel onMouseLeave={toggle}>
            {data?.map((item: LCTopicsProps, key: number) => (
              <NavSubItem key={key} onClick={() => toggleHandler(item)}>
                {item.display_name}
              </NavSubItem>
            ))}
          </NavPanel>
        </NavPanelStyled>
      ) : (
        <DropdownFiltersNative />
      )}
    </NavFilter>
  );
};
