import { navigate } from 'gatsby';
import React from 'react';
import { LCMenuData } from '../lc-menu.interface';
import { DropdownNativeStyled } from './dropdown.styles';

export const DropdownMenuNative = ({ items }: { items: LCMenuData[] }) => {
  return (
    <DropdownNativeStyled
      onChange={(e: React.SyntheticEvent<any>) => {
        const url = e.currentTarget.value;
        navigate(url);
      }}
    >
      <option value={''}>Select...</option>
      {items?.map((item: LCMenuData, key: number) => {
        return (
          <option value={item.url} key={key}>
            {item?.label}
          </option>
        );
      })}
    </DropdownNativeStyled>
  );
};
