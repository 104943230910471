import React from 'react';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-emotion';
import BasicLink from '../../basic-link';
import CMSComponentWrapper from '../../component-wrapper';
import { DropdownMenuNative } from './dropdown';
import { LCMenuData, LCMenuProps } from './lc-menu.interface';
import {
  LCMenuNavContainer,
  LCMenuNav,
  LCMenuNavStyled,
  LeftMenuStyled,
  LogoText,
  LogoTextStyled,
  Menu,
  MenuItem,
} from './lc-menu.styles';
import { Filters } from './nav-filters/filters';
import { NavItemIconStyled } from './nav-filters/filters.styles';
import { Tags } from './nav-tags/Tags';

export const LCMenu: React.FC<LCMenuProps> = ({
  logoText,
  menu,
  filters,
  backgroundColorMenu,
  backgroundColorFilter,
}) => {
  const isDesktop = useBreakpoint(up('lg'));
  return (
    <LCMenuNavContainer>
      <LCMenuNav
        data-testid="lcmenu-nav__container"
        backgroundColor={backgroundColorMenu}
      >
        <CMSComponentWrapper maxWidth={1240}>
          <LCMenuNavStyled>
            <LeftMenuStyled>
              {logoText && (
                <LogoTextStyled>
                  <LogoText backgroundColor={backgroundColorMenu}>
                    <BasicLink url="/learning-center" text={logoText} />
                  </LogoText>
                  {!isDesktop && <NavItemIconStyled state={'closed'} />}
                </LogoTextStyled>
              )}
              {menu &&
                !!menu.length &&
                (isDesktop ? (
                  <Menu backgroundColor={backgroundColorMenu}>
                    {menu?.map((item: LCMenuData, key: number) => (
                      <MenuItem
                        key={key}
                        active={item.active}
                        backgroundColor={backgroundColorMenu}
                      >
                        <BasicLink
                          url={item?.url || ''}
                          text={item?.label}
                          isExternal={false}
                        />
                      </MenuItem>
                    ))}
                  </Menu>
                ) : (
                  <DropdownMenuNative items={menu} />
                ))}
            </LeftMenuStyled>

            {filters && !!filters.length && (
              <Filters items={filters} backgroundColor={backgroundColorMenu} />
            )}
          </LCMenuNavStyled>
        </CMSComponentWrapper>
      </LCMenuNav>
      {filters && !!filters.length && (
        <Tags backgroundColor={backgroundColorFilter} />
      )}
    </LCMenuNavContainer>
  );
};
