import styled from '@emotion/styled';

export const DropdownNativeStyled = styled.select`
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 0;
  top: 0;
  height: 100%;
`;
